import url from "url";
import QS from "querystring";
import Router from "next/router";
import * as withConditions from "@insightfulscience/smart-react/hocs/WithConditions";

import { makeRefs, buildQuery } from "./smart-utils/url-refs";
import { splitParams } from "./utils/split-params";
import { mapServerSideRedirectUrl } from "./utils/redirectIf";

const routes = {
	serverInfo: "/server-info",
	login: "/auth/login",
	signUp: "/auth/signup",
	exchange: "/auth/exchange",
	forgotPassword: "/auth/forgot-password",
	forgotPasswordSuccess: "/auth/forgot-password/success",
	resetPasswordSuccess: "/auth/reset-password/success",
	resetPasswordFail: "/auth/reset-password/fail",
	myAccount: "/account",
	myAccountPreferences: "/account/preferences",
	purchaseBase: "/purchase/",
	selectSeats: "/purchase/select-seats",
	billingInfo: "/purchase/billing",
	confirmRenewal: "/purchase/confirm-renewal",
	placeOrder: "/purchase/payment",
	purchaseSuccess: "/purchase/invoice",
	logout: "/auth/sign-out",
	freeTrial: "/free-trial",
	snapgeneViewer: "/snapgene-viewer",
	snapgeneViewerDownload: "/snapgene-viewer/download",
	completeProfile: "/free-trial/complete-profile",
	confirmEmail: "/free-trial/confirm-email",
	home: {
		url: "/",
		disabledEnv: [],
	},
	entitlement: {
		dashboard: "/account/entitlements",
		activations: "/account/entitlements/activations",
	},
	subscription: {
		dashboard: "/account/subscription",
		activations: "/account/subscription/activations",
		invoice: "/account/subscription/invoice",
		settings: "/account/subscription/settings",
		entitlements: {
			index: "/account/subscription/entitlements",
			activations: "/account/subscription/entitlements/activations",
		},
		purchaseActivations: "/account/subscription/purchase-activations",
		renew: "/account/subscription/renew",
		billing: {
			index: "/account/subscription/billing",
			purchaseActivations: "/account/subscription/billing/activations",
			changePaymentMethod: "/account/subscription/billing/changepayment",
		},
		billingInfoUpdate: "/account/subscription/billinginfo-update",
		cancelConfirm: "/account/subscription/cancel-confirm",
		cancelSurvey: "/account/subscription/cancel-survey",
		upgradeTrialPlan: "/account/subscription/upgrade-trial-plan",
		upgradeTrialPayment: "/account/subscription/upgrade-trial-payment",
		upgradeTrialSuccess: "/account/subscription/upgrade-trial-success",
		machineTokens: "/account/subscription/tokens",
		sso: "/account/subscription/sso",
		scim: "/account/subscription/scim",
		domains: "/account/subscription/domains",
	},
	support: {
		shareDownloads: "/support/downloads",
	},
	perpetual: {
		dashboard: "/account/perpetual",
		activations: "/account/perpetual/activations",
		settings: "/account/perpetual/settings",
	},
	invoice: {
		pay: "/invoice/pay",
		invalid: "/invoice/invalid",
		success: "/invoice/pay-success",
	},
	errors: {
		403: "/403",
		404: "/404",
		default: "/error",
	},
	schoolPetition: "/school-petition",
};

routes.external = {
	howToBuy: "/pricing",
	support: "https://support.snapgene.com/hc/en-us/",
	supportNewRequest: "https://support.snapgene.com/hc/en-us/requests/new",
	termsOfService: "/legal-disclaimers",
	privacyPolicy: "//www.dotmatics.com/privacy-policy",
	dotmatics: "https://www.dotmatics.com",
	portfolio: "https://www.dotmatics.com/portfolio",
	careers: "https://www.dotmatics.com/about-careers",
	doNotSell: "https://donotsell.dotmatics.com/hc/en-us/requests/new",
	platform: "//www.dotmatics.com/platform",
	products: {
		prism:
			"//www.graphpad.com/?utm_medium=referral&utm_source=snapgene&utm_campaign=dotmatics-top-bar",
		geneious:
			"//www.geneious.com/?utm_medium=referral&utm_source=snapgene&utm_campaign=dotmatics-top-bar",
		snapgene:
			"//www.snapgene.com/?utm_medium=referral&utm_source=snapgene&utm_campaign=dotmatics-top-bar",
		labArchives:
			"//www.labarchives.com/?utm_medium=referral&utm_source=snapgene&utm_campaign=dotmatics-top-bar",
		proteinMetrics:
			"//proteinmetrics.com/?utm_medium=referral&utm_source=snapgene&utm_campaign=dotmatics-top-bar",
		omiq: "//www.omiq.ai/?utm_medium=referral&utm_source=snapgene&utm_campaign=dotmatics-top-bar",
		fcsExpress:
			"//denovosoftware.com/?utm_medium=referral&utm_source=snapgene&utm_campaign=dotmatics-top-bar",
		nQuery:
			"//www.statsols.com/?utm_medium=referral&utm_source=snapgene&utm_campaign=dotmatics-top-bar",
	},
	machineAccessTokenActivation:
		"https://support.snapgene.com/hc/en-us/faq/machine-access-token-activation/",
	nonFloatingLicenseInfoUrl: "https://support.snapgene.com/hc/en-us/articles/31244764445844",
	ssoGuide:
		"https://support.snapgene.com/hc/en-us/articles/31440373185428-snapgene-single-sign-on-sso-guide",
	scimGuide:
		"https://support.snapgene.com/hc/en-us/articles/31440479622164-snapgene-directory-sync-scim-guide",
	domainsGuide:
		"https://support.snapgene.com/hc/en-us/articles/31440535174548-snapgene-domain-verification-guide",
	trademarks: "https://www.dotmatics.com/trademarks",
};

export default routes;

export const refs = makeRefs(routes);

export const navToPath = (path, as, action = "push") => Router[action](path, as);

export const gotoPath = (path, as, action) => () => navToPath(path, as, action);

export const toLoginUrl = ({ returnUrl, ...params }) =>
	`${routes.login}${buildQuery({ returnUrl: encodeURIComponent(returnUrl), ...params })}`;

const getReturnUrl = (pathname, queryParams, path = null) =>
	// prettier-ignore
	queryParams.returnUrl ? queryParams.returnUrl :
	pathname === routes.exchange ? routes.myAccount	:
	path; /* otherwise */

export const cleanReturnUrl = path => {
	const { pathname, query } = url.parse(path);
	return getReturnUrl(pathname, QS.parse(query), path);
};

const isUtmParam = ([param]) => /^utm_/i.test(param);

export const toLogin = ({ asPath }) => {
	const { pathname, query } = url.parse(asPath);
	const [, restParams] = splitParams(QS.parse(query), isUtmParam);

	return toLoginUrl({
		returnUrl: getReturnUrl(pathname, restParams) ?? `${pathname}${buildQuery(restParams)}`,
	});
};

export const toLogout = ({ asPath }) =>
	`${routes.logout}?returnUrl=${encodeURIComponent(cleanReturnUrl(asPath))}`;

const decode = path => path && decodeURIComponent(path);

const isAbsoluteURL = urlString => /^([a-z]+:)?\/\//i.test(urlString);

export const toReturnUrl = fallback => ({ query }) =>
	!isAbsoluteURL(query.returnUrl) ? decode(query.returnUrl) || fallback : routes.myAccount;

const getQuery = () => QS.parse(global.location.search.replace(/^\?/, ""));

export const followToReturlUrl = ({ fallback }) => {
	const resolveReturnUrl = toReturnUrl(fallback);
	return () => Router.replace(resolveReturnUrl({ query: getQuery() }));
};

export const toError = ({ code, message }) => {
	return routes.errors[code] !== undefined
		? `${routes.errors[code]}?${QS.stringify({ code, message })}`
		: `${routes.errors.default}?${QS.stringify({ code, message })}`;
};

const search = searchString => searchString && `?${searchString}`;
export const ref = (path, query) => `${path}${search(QS.stringify(query))}`;

export const subscrRef = (path, subscriptionID) => `${path}?subscriptionId=${subscriptionID}`;

export const toSubscriptionDashboard = ({ subscriptionID }) =>
	subscrRef(routes.subscription.dashboard, subscriptionID);

export const toCompleteProfilePage = trialId =>
	Router.push({ pathname: routes.completeProfile, query: { trialId } });

export const toFreeTrialPage = () => `${routes.freeTrial}`;
export const toSnapgeneViewerPage = () => `${routes.snapgeneViewer}`;

const presereveUtmParams = (pathOrUrl, { query }) => {
	const parsedUrl = url.parse(pathOrUrl);

	if (parsedUrl.hostname) return pathOrUrl;

	return url.format({
		...parsedUrl,
		search: null,
		query: {
			...QS.parse(parsedUrl.query),
			...splitParams(query, isUtmParam)[0],
		},
	});
};

mapServerSideRedirectUrl(presereveUtmParams);
withConditions.mapServerSideRedirectUrl(presereveUtmParams);
